import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Link } from '@mui/material';

const SignUpForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      // const response = await axios.post('https://your-api-endpoint.com/signup', { name, email, password });

      // const { userData, token } = response.data;

      setMessage('Signup successful');
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'An error occurred');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full height to vertically center
    
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Sign Up
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '100%', sm: '400px' }, // 100% on small screens, 400px on larger screens
          padding: 2,
          backgroundColor: '#fff',
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <TextField
          label="Name"
          type="text"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          margin="normal"
          fullWidth
        />
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          margin="normal"
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          margin="normal"
          fullWidth
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          margin="normal"
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 3 }}>
          Sign Up
        </Button>
        {message && (
          <Typography color="error" sx={{ marginTop: 2 }}>
            {message}
          </Typography>
        )}
      </Box>
      <Link href="/login" variant="body2" sx={{ marginTop: 2 }}>
        Already have an account? Login
      </Link>
    </Box>
  );
};

export default SignUpForm;
