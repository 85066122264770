// src/components/LoginForm.js
import React, { useState } from "react";
import { Box, Button, TextField, Typography, Link } from "@mui/material";

import { loginUser } from "../../api/auth";
import { setSession } from "../../utills/jwt";
import {useAuthContext} from '../../context/hooks/use-auth-context'

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const {checkUserSession} = useAuthContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      const credentials = { email, password };
      const response = await loginUser(credentials); // Ensure to await the result
      // Extract user data and token from the response
      const { token } = response; // Adjust based on actual response structure
      if (!response.success) {
        setMessage("Invalid Credentials");
      }
      await setSession(token);
      await checkUserSession?.()
      setMessage("Login successful");
    } catch (error) {
      setMessage(
        error.response ? error.response.data.message : "An error occurred"
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Full height to vertically center
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", sm: "400px" }, // 100% on small screens, 400px on larger screens
          padding: 2,
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          margin="normal"
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          margin="normal"
          fullWidth
        />
        <Link
          href="/forgotPassword"
          variant="body2"
          sx={{ marginTop: 1, textAlign: "right" }}
        >
          Forgot Password?
        </Link>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: 3 }}
        >
          Login
        </Button>
        {message && (
          <Typography color="error" sx={{ marginTop: 2 }}>
            {message}
          </Typography>
        )}
      </Box>
      <Link href="/signUp" variant="body2" sx={{ marginTop: 2 }}>
        Don't have an account? Sign up
      </Link>
    </Box>
  );
};

export default LoginForm;
