import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const Cards = ({ imageURL, title, price }) => {
  return (
    <Card sx={{ maxWidth: 250, height: '250px', boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
      <CardMedia
        component="img"
        height="60%"
        image={imageURL}
        alt={title}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '40%' }}>
        {/* Title Display */}
        <Typography
          variant="subtitle1" // Change to a smaller variant
          sx={{
            fontWeight: 'bold',
            color: 'black',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </Typography>
        
        {/* Price Display */}
        <Typography variant="body2" sx={{ color: 'blue' }}> {/* Decreased margin top */}
          {price}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Cards;
