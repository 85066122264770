// src/Layout.js
import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Footer from '../footer/footer';  
import Header from '../header/header';
import { Outlet } from 'react-router-dom'; // Import Outlet

const Layout = () => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          width: "100%",
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        {/* Header at the top */}
        <Header />

        {/* Main content section */}
        <Box
          component="main"
          sx={{
            flex: 1,
            mt: '-64px',
            padding: 0,
            margin: 0,
          }}
        >
          {/* Use Outlet to render the child routes */}
          <Outlet />
        </Box>

        {/* Footer at the bottom */}
        <Footer />
      </Box>
    </>
  );
};

export default Layout;
