import React, { createContext, useReducer, useContext } from 'react';

// Initial state for the cart
const initialState = {
  cartItems: [
    {
      id: 1,
      name: 'Game dshfhds df dsfdsj dsdsf dfsdf sdsa sdasd asdas sadas asdas saddas asdads ',
      price: 29.99,
      imageURL: '/assets/background.png',
      quantity: 1, // Add default quantity
    },
    {
      id: 2,
      name: 'Game sdf dsfds dsfds',
      price: 49.99,
      imageURL: '/assets/background.png',
      quantity: 1, // Add default quantity
    },
  ],
};

// Reducer function to handle actions like adding/removing items
const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItem = state.cartItems.find(item => item.id === action.payload.id);
      if (existingItem) {
        // Update the quantity
        return {
          ...state,
          cartItems: state.cartItems.map(item =>
            item.id === action.payload.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        };
      }
      // Add new item to cart
      return {
        ...state,
        cartItems: [...state.cartItems, { ...action.payload, quantity: 1 }],
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter(item => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

// Create Cart Context
const CartContext = createContext();

// CartProvider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  // Save the cart state to local storage whenever it changes
  React.useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(state.cartItems));
  }, [state.cartItems]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook to use the CartContext
export const useCart = () => {
  return useContext(CartContext);
};

// Function to calculate the total number of items in the cart
export const getTotalItemsInCart = (cartItems) => {
  return cartItems.reduce((total, item) => total + item.quantity, 0);
};
