// endpoints/category.js
import axiosInstance, { endpoints } from '../utills/axios';

// Create a new category
export const createCategory = async (categoryData) => {
  try {
    const response = await axiosInstance.post(endpoints.category.new, categoryData);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Edit an existing category
export const editCategory = async (id, updatedData) => {
  try {
    const response = await axiosInstance.put(endpoints.category.edit(id), updatedData);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Get details of a specific category by ID
export const getCategoryDetails = async (id) => {
  try {
    const response = await axiosInstance.get(endpoints.category.details(id));
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Get all categories
export const getAllCategories = async () => {
  try {
    const response = await axiosInstance.get(endpoints.category.all);  // Assuming this is the listing route
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Remove a category by ID
export const removeCategory = async (id) => {
  try {
    const response = await axiosInstance.delete(endpoints.category.remove(id));
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
