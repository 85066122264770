import { useState, useEffect } from 'react';

import {useAuthContext} from '../context/hooks/use-auth-context'

import {  useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function AuthGuard({ children }) {
  const navigate = useNavigate();

  const { authenticated, loading } = useAuthContext();
  const [isChecking, setIsChecking] = useState(true);

  const checkPermissions = async () => {
    if (loading) {
      return;
    }

    if (!authenticated) {
      navigate('/login');
      return;
    }
    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, loading]);

  if (isChecking) {
    return <div>Loading please wait we are giving you a pleasure...</div>;
  }

  return <>{children}</>;
}

