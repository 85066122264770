import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React from 'react';
import { Box } from "@mui/material";
import ImageCard from "../card/ImageCard";

const CarouselMulti = ({ carouselData }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1140 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 684 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1
    },
    mobile: {
      breakpoint: { max: 684, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1
    }
  };

  return (
    <Box
      sx={{
        width: { xs: '90vw', md: '80vw' }, // responsive width
        margin: '0 auto', // centers horizontally
      }}
    >
      {carouselData?.length > 0 && <Carousel responsive={responsive}>
        {carouselData?.map((item) => <ImageCard id={item?._id} imageURL={item?.images[0]} name={item?.name} description={item?.description} />)}
      </Carousel>}
    </Box>
  );
};

export default CarouselMulti;
