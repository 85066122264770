import React from "react";
import { Box, Typography } from "@mui/material";
import MultiCarousel from "../carousel/Carousel";

const Landing = ({carouselData}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "99vh",
        backgroundImage: "url('/assets/background.png')", // Replace with your image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <Box
        sx={{
          width: { xs: "95%", md: "80%" }, // responsive width
          height: "98%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end", // Center vertically
          alignItems: "center", // Center horizontally
          gap: 10,
        }}
      >
        <Box>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ fontSize: { xs: "18px", md: "24px" } }}
          >
            Welcome to My Website
          </Typography>
          <Typography variant="body1">
            This is the main content area.
          </Typography>
        </Box>
        <MultiCarousel carouselData={carouselData} />
      </Box>
    </Box>
  );
};

export default Landing;
