import React from 'react';
import { Box, Typography } from '@mui/material';
import ImageCard from '../card/ImageCard'; // Ensure this path is correct

const Category = ({ categoryName, cardData }) => {
  return (
    <Box
      sx={{
        marginBottom: 4,
        padding: '0 16px', // Space on the left and right
      }}
    >
      {/* Category Title */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          marginBottom: 1,
          borderBottom: '2px solid lightgrey',
          paddingBottom: 1
        }}
      >
        {categoryName}
      </Typography>

      {/* Cards Container */}
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          gap: 2,
          padding: '8px 0', // Adjust padding as needed
          scrollBehavior: 'smooth',
          '::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar
          },
          msOverflowStyle: 'none',  // For Internet Explorer and Edge
          scrollbarWidth: 'none', // For Firefox
        }}
      >
        {cardData.length > 0
          ? cardData.map((card, index) => (
            <Box
              key={index}
              sx={{
                flex: '0 0 auto', // Prevent cards from growing or shrinking
                minWidth: '200px', // Minimum width for cards
                maxWidth: '300px', // Maximum width for cards
                boxSizing: 'border-box', // Ensures padding and border are included in the element's width and height
              }}
            >
              <ImageCard
                id={card._id}
                imageURL={card.images[0]}
                name={card.name}
                description={card.description}
                handleBuy={card.handleBuy}
              />
            </Box>
          ))
          :
          <Typography sx={{ width: '100%', textAlign: 'center' }}>No Game Found For this Category</Typography>}
      </Box>
    </Box>
  );
};

export default Category;
