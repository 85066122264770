export const paths = {
    auth: {
      signIn: `/login`,
     
    },
   
  };

  export const roleRoutes = {
    user: ['/', '/gameDetail'],
    admin: ['/dashboard','/dashboard/category', '/dashboard/games', '/gameDetail',],
  };