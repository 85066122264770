
import axiosInstance,{ endpoints}  from '../utills/axios';  



export const loginUser = (credentials) => {
  return axiosInstance.post(endpoints.auth.signIn, credentials)  
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const logout = () => {
  localStorage.removeItem("Token");
  localStorage.removeItem("Token_expirey");
};