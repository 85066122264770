import React, { useMemo, useEffect, useCallback, useState } from "react";
import axiosInstance, { endpoints } from "../utills/axios"; // Correct import path for axiosInstance
import {AuthContext} from "./authContext"; // Correct import path for AuthContext
import { setSession, isValidToken } from "../utills/jwt"; // Correct import path for jwt utility functions

export default function AuthContextProvider({ children }) {
  // Initialize state with useState
  const [state, setState] = useState({
    user: null,
    loading: true,
  });

  // Function to check and validate user session
  const checkUserSession = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("Token"); // Retrieve token from local storage

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken); // Set token in headers for axios

        // Fetch user info
        const {data} = await axiosInstance.get(endpoints.auth.me);          
        // Update state with user info and token
        setState({
          user: { ...data.user, accessToken },
          loading: false,
        });
      } else {
        // Clear state if no valid session
        setState({ user: null, loading: false });
      }
    } catch (error) {
      console.error('Error checking user session:', error);
      // Handle errors and clear state
      setState({ user: null, loading: false });
    }
  }, []); // Dependency array remains empty, so it runs once on mount

  useEffect(() => {
    checkUserSession(); // Check session on component mount
  }, [checkUserSession]);

  // Determine status for authentication and loading
  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";
  const status = state.loading ? "loading" : checkAuthenticated;

  // Memoized value for context to optimize performance
  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      checkUserSession,
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
    }),
    [checkUserSession, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
