// src/auth/GuardedRoute.jsx
import { AuthGuard } from './auth-guard';
import { RoleBasedGuard } from './role-based-guard';

// A utility to combine AuthGuard and RoleBasedGuard
export function GuardedRoute({ currentRole, requiredRole, children }) {
  return (
    <AuthGuard>
      <RoleBasedGuard currentRole={currentRole} acceptRoles={[requiredRole]} hasContent>
        {children}
      </RoleBasedGuard>
    </AuthGuard>
  );
}
