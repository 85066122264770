import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Edit, 
  // Delete
 } from '@mui/icons-material';
import { createCategory, getAllCategories, editCategory, 
  // removeCategory
 } from '../api/category';

const CategoryManager = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [open, setOpen] = useState(false);

  // Fetch all categories on load
  useEffect(() => {
    const fetchCategories = async () => {
      const result = await getAllCategories();

      setCategories(result?.categories?.filter((item) => item?._id !== '66e9430c50fd4d188ea7a8ec'));
    };
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    if (name.trim()) {
      const newCategory = { name, description };
      await createCategory(newCategory);
      setCategories([...categories, newCategory]);
      setName('');
      setDescription('');
    }
  };

  const handleEditClick = (id, categoryName, categoryDescription) => {
    setIsEditMode(true);
    setEditId(id);
    setName(categoryName);
    setDescription(categoryDescription);
    setOpen(true);
  };

  const handleEditCategory = async () => {
    const updatedCategory = { name, description };
    await editCategory(editId, updatedCategory);
    setCategories(categories.map(cat => (cat._id === editId ? updatedCategory : cat)));
    setIsEditMode(false);
    setEditId(null);
    setName('');
    setDescription('');
    setOpen(false);
  };

  // const handleDeleteCategory = async (id) => {
  //   await removeCategory(id);
  //   setCategories(categories.filter(cat => cat._id !== id));
  // };

  const handleDialogClose = () => {
    setOpen(false);
    setIsEditMode(false);
    setName('');
    setDescription('');
  };

  return (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Category Manager
      </Typography>

      {/* Form for adding/editing category */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 400, maxWidth: 400, mb: 4 }}>
        <TextField
          label="Category Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
        />
        <TextField
          label="Category Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          fullWidth
        />
        {isEditMode ? (
          <Button variant="contained" color="primary" onClick={handleEditCategory}>
            Save Changes
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleAddCategory}>
            Add Category
          </Button>
        )}
      </Box>

      {/* List of Categories */}
      <Typography variant="h6" gutterBottom>
        Categories
      </Typography>
      <List sx={{ width: 400, maxWidth: 400 }}>
        {categories?.map?.((cat) => (
          <ListItem key={cat._id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText primary={cat.name} secondary={cat.description || 'No description provided'} />
            <Box>
              <IconButton onClick={() => handleEditClick(cat._id, cat.name, cat.description)}>
                <Edit color="primary" />
              </IconButton>
              {/* <IconButton onClick={() => handleDeleteCategory(cat._id)}>
                <Delete color="error" />
              </IconButton> */}
            </Box>
          </ListItem>
        ))}
      </List>

      {/* Edit Dialog */}
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Category Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">Cancel</Button>
          <Button onClick={handleEditCategory} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CategoryManager;
