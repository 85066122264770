import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Landing from '../components/landing/Landing';
import Category from '../components/category/Category';
import { listCarousel, listProduct } from '../api/product'; // Import the listProduct function
import { Box } from '@mui/material';

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [carouselData, setCarouselData] = useState([]);
  console.log("🚀 ~ Home ~ carouselData:", carouselData)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search') || '';
  useEffect(() => {
    const fetchCarouselProducts = async () => {
      try {
        const data = await listCarousel();
        console.log("🚀 ~ fetchCarouselProducts ~ data:", data)
        setCarouselData(data?.carouselListing);
      } catch (error) {
        console.error('Error fetching carousel products:', error);
      }
    };

    fetchCarouselProducts();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await listProduct({ search: searchQuery });
        setCategories(data?.productListing);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [searchQuery]);

  return <React.Fragment>
    <Landing carouselData={carouselData} />
    <Box sx={{ mt: 10 }}>
      {categories?.map((data, index) => (
        <Box sx={{ width: '90%', margin: '0 auto' }}>
          <Category key={index} categoryName={data.categoryName} cardData={data.cardData} />
        </Box>
      ))}
    </Box>
  </React.Fragment>;
};

export default Home;
