import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { useNavigate } from 'react-router-dom'; 
import { useCartContext } from '../../context/hooks/use-cart-context';
import Box from '@mui/material/Box'; // Import Box

function ImageCard({ id, imageURL, name, description, price }) {
  const navigate = useNavigate();
  const { addToCart } = useCartContext();

  const handleCardClick = () => {
    navigate(`/gameDetail/${id}`);
  };

  const game = {
    id,
    imageURL,
    name,
    description,
    price,
  };

  return (
    <Card sx={{ maxWidth: 250, minWidth: 250, boxShadow: 3, borderRadius: 2, overflow: 'hidden', height: '260px', }}>
      <CardActionArea onClick={handleCardClick}>
        <CardMedia
          component="img"
          height="60%" // Adjusted height to cover 50% of the card
          image={imageURL}
          alt={name}
          sx={{ objectFit: 'cover' }}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '40%' }}>
          {/* Price Display */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'blue', mb: 0.5 }}>
            ${price}
          </Typography>

          {/* Description Display */}
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(0, 0, 0, 0.7)',
              mb: 0.5,
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {description}
          </Typography>

          {/* Dots as a separator */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0.5 }}>
            <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.3)', textAlign: 'center' }}>...</Typography>
          </Box>

          {/* Game Name */}
          <Typography
            gutterBottom
            variant="body2" // Changed from h5 to body2 for smaller font size
            component="div"
            sx={{
              fontWeight: 'bold',
              color: 'black',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '0.9rem', // Further reduced font size
            }}
          >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => addToCart(game)}>
          Add to Cart
        </Button>
      </CardActions>
    </Card>
  );
}

export default ImageCard;
