import Layout from "./components/layout/layout";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login.jsx";
import SignUpForm from "./components/auth/SignUpForm.jsx";
import GameForm from "./components/Dashbooard/GameForm.jsx";
import GamesPage from "./pages/GamesPage.jsx";
import GameDetail from "./pages/GameDetail.jsx";
import ForgotPassword from "./components/auth/ForgotPassword.jsx";
import ResetPasswordPage from "./pages/ResetPasswordPage.jsx";
import { GuestGuard } from './auth/guest-guard.jsx';
import { AuthGuard } from './auth/auth-guard.jsx';
import { GuardedRoute } from './auth/GuardedRoute.jsx';

import { useAuthContext } from "./context/hooks/use-auth-context.js";
import CategoryManager from "./pages/CategoryPage.jsx";
import Cart from "./components/cartDetails/Cart.jsx";


function App() {
  const { user } = useAuthContext();
  return (
    <Router>

      <Routes>
        <Route path="/login" element={<GuestGuard><Login /></GuestGuard>} />
        <Route path="/register" element={<SignUpForm />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<AuthGuard><ResetPasswordPage /></AuthGuard>} />
        <Route path="/gameDetail/:id" element={<GameDetail />} />
        <Route path="/cart" element={<Cart/>}/>


        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<GuardedRoute currentRole={user?.role} hasContent><GameForm /></GuardedRoute>} />
          <Route path="/dashboard/category" element={<GuardedRoute currentRole={user?.role} hasContent><CategoryManager /></GuardedRoute>} />
          <Route path="/dashboard/games" element={<GuardedRoute currentRole={user?.role} hasContent><GamesPage /></GuardedRoute>} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>


    </Router>
  );
}

export default App;
