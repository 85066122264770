/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Card, CardMedia, CardContent, CircularProgress, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getProductDetails } from '../../api/product';

const CardDetail = () => {
  const { id } = useParams(); // Get the card ID from URL params
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchGameDetails = async () => {
    try {
      const response = await getProductDetails(id);
      if (response.success) {
        setGameData(response?.product);
      }
    } catch (error) {
      console.log("🚀 ~ useEffect ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameDetails();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!gameData?._id) {
    return <Typography variant="h5">Game not found.</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box component="div" sx={{ display: 'none' }}>
        <style>{`
          .blue {
            color: #0000FF; /* Simple blue color */
          }
        `}</style>
      </Box>
      <Card sx={{ padding: 2, boxShadow: 3 }}>
        {/* Thumbnail Image */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            image={gameData?.thumbnail}
            alt={gameData?.name}
            sx={{ width: '100%', maxWidth: '500px', borderRadius: 2 }}
          />
        </Box>

        {/* Game Info */}
        <CardContent>
          <Typography 
            variant="h4" 
            align="center" 
            sx={{ fontWeight: 'bold', mb: 2 }} 
            className="blue" // Apply blue class
          >
            {gameData?.name}
          </Typography>

          {/* YouTube Link */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <Link href={gameData?.youtubeLink} target="_blank" rel="noopener noreferrer" underline="hover">
              Watch Gameplay on YouTube
            </Link>
          </Box>

          {/* Game Description */}
          <Typography variant="body1" sx={{ mb: 3 }}>
            {gameData?.description}
          </Typography>

          {/* Price */}
          <Typography 
            variant="h5" 
            sx={{ mb: 3, fontWeight: 'bold' }} 
            className="blue" // Apply blue class
          >
            Price: <span>${gameData?.price}</span>
          </Typography>

          {/* Additional Images */}
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
            {gameData?.images?.map?.((image, index) => (
              <CardMedia
                key={index}
                component="img"
                image={image}
                alt={`Game Screenshot ${index + 1}`}
                sx={{ width: { xs: '100%', sm: '200px' }, borderRadius: 2, mb: 2 }}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CardDetail;
