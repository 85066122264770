import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Grid } from '@mui/material';
import { useCartContext } from '../../context/hooks/use-cart-context';

function Cart() {
  const { cartItems, removeFromCart, getTotalPrice } = useCartContext(); // Access the cart context

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, height: '100vh' }}>
      <Grid container spacing={2}>
        {/* Left Side: Table for Cart Items */}
        <Grid item xs={12} md={8}>
          <Typography variant="h4" align="center" gutterBottom>
            Your Cart
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Price</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <img
                        src={item.imageURL}
                        alt={item.name}
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        maxWidth: { xs: '100px', sm: '150px' }, // Adjust width for responsiveness
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      title={item.name} // Show full name on hover
                    >
                      {item.name}
                    </TableCell>
                    <TableCell align="center">
                      {item.price !== undefined && !isNaN(item.price) ? `$${item.price.toFixed(2)}` : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      <Button variant="contained" color="secondary" onClick={() => removeFromCart(item.id)}>
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Right Side: Total Price Section */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f5f5f5',
              padding: 4,
              borderRadius: 2,
              height: '100%',
            }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              Total Price
            </Typography>
            <Typography variant="h3" color="primary" align="center" gutterBottom>
              ${getTotalPrice() !== undefined && !isNaN(getTotalPrice()) ? getTotalPrice().toFixed(2) : '0.00'}
            </Typography>
            <Button variant="contained" color="primary" size="large">
              Proceed to Checkout
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Cart;
