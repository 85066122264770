import { useCart, getTotalItemsInCart } from "../CartContext";

export const useCartContext = () => {
  const { state, dispatch } = useCart();

  // Function to add a game to the cart
  const addToCart = (game) => {
    dispatch({ type: 'ADD_TO_CART', payload: game });
  };

  // Function to remove a game from the cart by ID
  const removeFromCart = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id } });
  };

  // Function to calculate the total price of the cart
  const getTotalPrice = () => {
    return state.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  // Function to get total number of items in the cart
  const getTotalItems = () => {
    return getTotalItemsInCart(state.cartItems);
  };

  return {
    cartItems: state.cartItems, // The items in the cart
    addToCart,                  // Function to add items to the cart
    removeFromCart,             // Function to remove items from the cart
    getTotalPrice,              // Function to calculate the total price of the cart
    getTotalItems,              // Function to get total number of items in the cart
  };
};
