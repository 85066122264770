// src/Footer.js
import React from "react";
import { Container, Typography, Box } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Container maxWidth="sm">
        {/* <Typography variant="body1">
          Your footer content goes here.
        </Typography> */}
        <Typography
          sx={{ color: "white" }}
          variant="body2"
          color="textSecondary"
          align="center"
        >
          {"© "}
          {new Date().getFullYear()}
          {" Your Company Name. All rights reserved."}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
