import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import Cards from '../cards/Cards'; // Ensure you import the Cards component
import queryString from 'query-string'; // npm install query-string


const CardsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Parse query parameters from the URL
  const { page = 1, limit = 4 } = queryString.parse(location.search);

  const [currentPage, setCurrentPage] = useState(Number(page));
  const [cardsPerPage, setCardsPerPage] = useState(Number(limit));
  const [cards, setCards] = useState([]);
  const [totalCards, setTotalCards] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://abd-gaming-be.vercel.app/api/v1/games?page=${currentPage}&limit=${cardsPerPage}`);
        const data = await response.json();
        
        setCards(data.games);
        setTotalCards(data.total);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentPage, cardsPerPage]);

  const totalPages = Math.ceil(totalCards / cardsPerPage);

  const updateQueryParams = (newPage, newLimit) => {
    const newQuery = queryString.stringify({ page: newPage, limit: newLimit });
    navigate(`?${newQuery}`);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      updateQueryParams(nextPage, cardsPerPage); // Update the URL
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      updateQueryParams(prevPage, cardsPerPage); // Update the URL
    }
  };

  const handleCardsPerPageChange = (event) => {
    const newLimit = event.target.value;
    setCardsPerPage(newLimit);
    setCurrentPage(1); // Reset to the first page
    updateQueryParams(1, newLimit); // Update the URL
  };

  const maxCardsPerPage = Math.min(totalCards, 50);

  return (
    <Box sx={{ padding: 2 }}>
      {/* Cards Per Page Selection */}
      <FormControl variant="outlined" sx={{ marginBottom: 2, minWidth: 120 }}>
        <InputLabel id="cards-per-page-label">Cards Per Page</InputLabel>
        <Select
          labelId="cards-per-page-label"
          value={cardsPerPage}
          onChange={handleCardsPerPageChange}
          label="Cards Per Page"
        >
          {[4, 10, 20, 30, maxCardsPerPage].filter(Boolean).map((number) => (
            <MenuItem key={number} value={number}>
              {number}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Cards Display */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          marginBottom: 4,
        }}
      >
        {cards.map((card) => (
          <Cards
            key={card.id}
            imageURL={card.imageURL}
            title={card.title}
            price={card.price}
          />
        ))}
      </Box>

      {/* Total Cards and Current Range */}
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        {`Showing ${currentPage * cardsPerPage - cardsPerPage + 1}-${Math.min(currentPage * cardsPerPage, totalCards)} out of ${totalCards} cards`}
      </Typography>

      {/* Navigation Arrows */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIos />
        </IconButton>

        <Typography variant="body2" sx={{ margin: '0 10px' }}>
          {`Page ${currentPage} of ${totalPages}`}
        </Typography>

        <IconButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CardsPage;
