
import axiosInstance,{ endpoints}  from '../utills/axios';  



export const productNew = (bodyData) => {
  return axiosInstance.post(endpoints.products.new, bodyData)  
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const listProduct = (queryParams) => {
    const queryString = new URLSearchParams(queryParams).toString();
    return axiosInstance.get(`${endpoints.products.listingForUser}?${queryString}`)  
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  };

  export const listCarousel = () => {
    return axiosInstance.get(endpoints.products.listingCarousel)  
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  };

  export const getProductDetails = (id) => {
    return axiosInstance.get(`${endpoints.products.details(id)}`)  
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  };