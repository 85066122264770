import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { roleRoutes } from '../routes/route';

export function RoleBasedGuard({ sx, children, hasContent, currentRole, acceptRoles }) {
    const location = useLocation(); // Get the current location
    const pathname = location.pathname; // Extract the pathname from the location
    console.log("pathname",pathname)
    // Check if the role has access to the current route
    const hasAccess = roleRoutes[currentRole]?.some((allowedRoute) => pathname.startsWith(allowedRoute));
    
    if (!hasAccess) {
        return hasContent ? (
            <Container sx={{ textAlign: 'center', ...sx }}>
                <div>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Permission denied
                    </Typography>
                </div>

                <div>
                    <Typography sx={{ color: 'text.secondary' }}>
                        You do not have permission to access this page.
                    </Typography>
                </div>

                <div>
                    {/* <ForbiddenIllustration sx={{ my: { xs: 5, sm: 10 } }} /> */}
                </div>
            </Container>
        ) : null;
    }

    return <>{children}</>;
}
